.root {
  position: fixed;
  z-index: var(--z-index-5x);
  inset: 0;
}

.spinner {
  --spinner-width: 6px;
  --spinner-size: 60px;
  --spinner-accent-color: var(--color-gray-200);
  position: absolute;
  z-index: var(--z-index-2x);
  left: 50%;
  top: 50%;
  transform: translate3d(-30px, -30px, 0);
}
